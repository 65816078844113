import React from "react"
import "./modal.css"

import savingsCard from "../../images/graphs/desktop/savingscard.jpg"





const SavingsCardModal = (props) => {
	// Get the modal
    return (
        <div className="modal">
          <div className="modal-content-amarin chart-modal">
      			<img className="modal-chart" src={savingsCard} alt=""/>
      			<button className="close-modal" onClick={props.closeModal}>X</button>			   	
          </div>
        </div>   
    )
}




export default SavingsCardModal
