import React from "react"
import "./modal.css"
// import totalevents_chart_zoom from '../../images/zoom_charts/1.3/totalevents_chart_zoom-2.png'
import reduction_in_CV_event from '../../images/graphs/reduction_in_CV_event.png'




const Chart1_3 = (props) => {
	// Get the modal

    return (
        <div className="modal">
         	<div className="modal-content-amarin chart-modal modal-title">
			 <button className="close-modal" onClick={props.closeModal}>X</button>
			 <h4 className="magenta">Reduction in first, subsequent, and total CV events with VASCEPA</h4>
				<img className="modal-chart" src={reduction_in_CV_event} alt=""/>
          	</div>
        </div>
    )
}

export default Chart1_3
