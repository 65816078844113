import React from "react"
import "./modal.css"

import vascepa_logo from "../../images/vascepa-logo.svg"


const LeavingSiteModal = (props) => {
	// Get the modal
	const styles = {
    	textDecoration: 'none'
  	};

	function closeModal() {
		const modal = document.getElementById("leaving-modal");
	 	modal.style.display = "none";
	}

	function init() {
		const modal = document.getElementById("leaving-modal");
		modal.style.display = "block";
	}

    return (
   		<div id="leaving-modal" className="modal">
			<div className="content-position-div">
				<div className="home-modal-content">
					<img src={vascepa_logo} alt=""/>
					<p className="continue-para">You are about to leave vascepahcp.com</p>
					<button className="close-modal" onClick={props.closeModal}>X</button>
					<a style={styles} href={props.href} target="_blank">
						<button className="cta-btn" onClick={props.closeModal}>Continue</button>
					</a>
					<button className="cta-btn" onClick={props.closeModal}>Cancel </button>	
				</div>
		 	</div>
		</div>
    )
}
export default LeavingSiteModal
