import React from "react"
import "./modal.css"
import ipe_illustration from '../../images/zoom_charts/2.1/IPE_zoom.png'




const IPE_zoom = (props) => { 
	// Get the modal

    return (
        <div className="modal">
         	<div className="modal-content-amarin chart-modal">
			 <button className="close-modal" onClick={props.closeModal}>X</button>
				<img className="modal-chart" src={ipe_illustration} alt=""/>
          	</div>
        </div>
    ) 
}

export default IPE_zoom