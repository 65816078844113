import React, { Component } from "react"

export default class ForceHash extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
      /// This component was implemented to address a bug with jump to links not working in mobile, the source of the bug still hasn't been identified; time permitting it should be and this should be removed. 
        if (window.location.hash){
            //console.log("forcing jump to if there is anchor tag hash")
            setTimeout(
            function(){window.location.href = window.location.href.split("#")[0] + window.location.hash},
            100
            )
        }

    }

  render() {

    return (
      <>
            <div className="forceHash"></div>

      </>
    )
  }
}

