import React from "react"
import "./modal.css"
import IPEChart_desk from "../../images/graphs/desktop/ipe_chart.jpg"




const Chart2_3 = (props) => { 
	// Get the modal

    return (
        <div className="modal">
         	<div className="modal-content-amarin chart-modal">
				<button className="close-modal" onClick={props.closeModal}>X</button>
				<img className="modal-chart" src={IPEChart_desk} alt=""/>	
          	</div>
        </div>
    ) 
}

export default Chart2_3