import React from "react"
import "./modal.css"
import Image from '../../images/graphs/desktop/Time_to_CABG.png'




const Time_to_CABG = (props) => {
    // Get the modal

    return (
        <div className="modal">
            <div className="modal-content-amarin chart-modal modal-title">
             <button className="close-modal" onClick={props.closeModal}>X</button>
             <h4 className="magenta">Time to CABG</h4>
                <img className="modal-chart" src={Image} alt=""/>
            </div>
        </div>
    )
}

export default Time_to_CABG