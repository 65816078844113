import React from "react"
import "./cookie.css"

/*images*/
import exit from "../../images/cookies-exit-desktop.png"

const Cookie = (props) =>  {


	return (
		<div className="cookies">

		    <p>
		        This site uses cookies to give you the best possible experience. By browsing our website, you agree to our use of cookies. If you require further information and/or do not wish to have cookies placed when using the site, visit the <a href="https://www.amarincorp.com/privacy_policy.html" rel="noopener noreferrer" target="_blank">Privacy Policy</a> page.
		    </p>
			<div className="exit close-icon" onClick={props.closeCookie}></div>
		</div>
	)



}


export default Cookie
