import React from "react"
import "./modal.css"
import trig_chart_zoom from "../../images/zoom_charts/1.2/trig_chart_zoom.png"

const Trig_chart_zoom = (props) => { 
	// Get the modal

    return (
        <div className="modal">
            <div className="modal-content-amarin chart-modal modal-title">
            <button className="close-modal" onClick={props.closeModal}>X</button>
            <h4 className="magenta">Primary endpoint by achieved triglyceride level at 1 year</h4>
                <img className="modal-chart" src={trig_chart_zoom} alt=""/>
            </div>
        </div>

    ) 
}

export default Trig_chart_zoom