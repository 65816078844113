import React, { Component } from "react"
import DesktopHeader from './desktopheader/DesktopHeader'
import MobileHeader from './mobileheader/MobileHeader'
import _ from 'underscore'
import { Waypoint } from 'react-waypoint';

export default class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
          isMobile: false,
          hideNav : false
        }
    }

    componentDidMount() {
    // console.log('componentDidMount')
    /*let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    let m = this.this.props.modal ? this.this.props.modal : null
    this.setState({
      isMobile: bool,
       modal: m
    })*/
    }

    componentWillUnmount () {
        // console.log('componentWillUnmount');
        // document.body.removeEventListener("scroll", this.handleScroll);
    }


    render() {

    const component = this.props.isMobile === true
        ? <MobileHeader
            hideNav={this.props.hideNav}
            goModal={this.props.goModal}/>
        :  <DesktopHeader
            hideNav={this.props.hideNav}
            goModal={this.props.goModal}
        />;

    return (
      <>

        {component}

      </>
    )
  }
}
