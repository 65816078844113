import React from "react"
import { Link } from "gatsby"
import "./footer.css"

/*images*/
import amarinLogo from "../../images/amarin-logo.svg"
import rarfLogo from "../../images/rarf-logo.png"
import AmarinModal from "../modal/AmarinModal"

export default class Footer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      isMobile: false,
    }

    this.showModal = this.showModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      let bool = window.matchMedia("only screen and (max-width: 1023px)")
        .matches
      this.setState({
        isMobile: bool,
      })
    }
  }

  showModal() {
    this.setState({ showModal: true })
    //console.log(this.state.showModal)
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  render() {
    var modal
    this.state.showModal
      ? (modal = <AmarinModal closeModal={this.closeModal} />)
      : (modal = null)

    return (
      <>
        {modal}
        <footer className="footer" id="footer">
          <div className="gradient-divider"></div>
            <div className="content-area">
              <nav>
                  <a href="http://www.amarincorp.com/privacy_policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>|
                  <a href="http://investor.amarincorp.com/contactUs.cfm" target="_blank" rel="noopener noreferrer">Contact Us</a>|
                  <a  href="/termsofservice.pdf" target="_blank" rel="noopener noreferrer">SMS Privacy Policy</a>|
                  <Link to="/site-map">Site Map</Link>|
                  <a href="https://investor.amarincorp.com/publications" target="_blank" rel="noopener noreferrer">Publications</a>
                   {this.state.isMobile ?  '|' : null }
                  <p className="amarin-modal-footer" onTouch={this.showModal} onClick={this.showModal}>About Amarin</p>

              </nav>

              <section className="trademark">
                  <p className="footer-copy m-b-10">
                    CO and VT prescribers only: Click <a target="_blank" href="https://amarincorp.com/wac"><strong>here</strong></a> for statutory price disclosure information.
                  </p>
                  <p className="footer-copy">
                      VASCEPA, Amarin, REDUCE-IT, and Vascepa/Amarin logos are registered trademarks of Amarin Pharmaceuticals Ireland Limited.
                  </p>
                  <p className="footer-copy">
                      All other trademarks are the property of their respective companies.
                  </p>
                  <p className="footer-copy">
                      &copy; 2023 Amarin Pharmaceuticals Ireland Limited.&nbsp;&nbsp;All rights reserved. <span className="nowrap">&nbsp;VAS-03038v14&nbsp;&nbsp;02/23</span>
                  </p>
              </section>


              <div className="logo-container">
                  <a href="http://www.amarincorp.com/" target="_blank" rel="noopener noreferrer">
                      <img className="footer-amarin-logo" src={amarinLogo} alt="Amarin logo"/>
                  </a>
                  
              </div>


              <div className="legal-copy">
                  <p className="footer-copy">This website, Vascepa.com, Vascepasavings.com, Truetoyourheart.com, and the corporation's website, amarincorp.com, are the only company-sanctioned websites pertaining to the Amarin group of companies or its product, Vascepa<sup>®</sup> (icosapent ethyl) capsules. The Amarin group of companies is not responsible for false or misleading materials contained on other non–Amarin-controlled websites or other social media sites.
                  </p>
              </div>
          </div>
        </footer>
      </>
    )
  }
}

Footer.defaultProps = {
  code: 'US-VAS-03038v6'
}