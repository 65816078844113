import React, { Component } from "react"
import HomeModal from "./HomeModal"
import "./modal.css"
import Amarin from "./AmarinModal"
import TrigChartZoom from "./Trig_chart_zoom"
import Chart1_2_2 from "./chart1_2_2"
import Chart1_5_2 from "./chart1_5_2"
import PrimaryendpointChartZoom from "./Primaryendpoint_chart_zoom"
import FirstCoronaryRevascularizationZoom from "./first_coronary_revascularization_zoom"
import Time_to_PCI from "./Time_to_PCI"
import Time_to_CABG from "./Time_to_CABG"
import TotaleventsChartZoom from "./Totalevents_chart_zoom"
import IPE_zoom from "./IPE_zoom"
import Chart2_3 from "./chart2_3"
import Chart3_3 from './chart3_3'
import SavingsCardModal from './SavingsCardModal'
import BlinkrxCardModal from './BlinkrxCardModal'
import LeavingSiteModal from './LeavingSiteModal'
import FirstCoronaryRevasc from './FirstCoronaryRevasc'
import CompositePrimary from './CompositePrimary'
import CompositeSecondary from './CompositeSecondary'
import ReductionPrimary from './ReductionPrimary'


export default class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: null,
      href: null,
    }

    this.closeModal = this.closeModal.bind(this)
    this.init = this.init.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.modal !== this.props.modal) {
      this.setState({ modal: this.props.modal })
    }
  }

  closeModal() {
    const modal = document.querySelector(".modal")
    // const content = document.querySelector(".modal-content-ameron");
    //  content.classList.add('offscreen');
    modal.style.display = "none"
    this.props.modalClosed()
  }

  init() {
    const modal = document.querySelector(".modal")
    // const content = document.querySelector(".modal-content-ameron");
    // content.classList.remove('offscreen');
    modal.style.display = "flex"
  }

  getModal(str) {

  	if (str === 'home') {
  		return <HomeModal closeModal={this.closeModal}/>
  	
    } else if(str === 'Amarin') {
        return  <Amarin closeModal={this.closeModal}/>	 
    
    } else if(str === 'trig_chart_zoom') {
        return  <TrigChartZoom closeModal={this.closeModal}/>	
    
    } else if(str === 'chart-1.2-2') {
        return  <Chart1_2_2 closeModal={this.closeModal}/>
   
    } else if(str === 'primaryendpoint_chart_zoom') {
        return  <PrimaryendpointChartZoom closeModal={this.closeModal}/>       	 
    
    } else if(str === 'totalevents_chart_zoom') {
        return  <TotaleventsChartZoom closeModal={this.closeModal}/>	 
    
    } else if(str === 'time_to_first_coronary_revascularization') {
        return  <FirstCoronaryRevasc closeModal={this.closeModal}/>	 
    
    } else if(str === 'composite_primary') {
        return  <CompositePrimary closeModal={this.closeModal}/>	 
    
    } else if(str === 'composite_secondary') {
        return  <CompositeSecondary closeModal={this.closeModal}/>	 
    
    } else if(str === 'Reduction_Primary') {
        return  <ReductionPrimary closeModal={this.closeModal}/>	 
    
    } else if(str === 'chart-1.5-2') {
        return  <Chart1_5_2 closeModal={this.closeModal}/>	 
    
    } else if(str === 'Time_to_PCI') {
        return  <Time_to_PCI closeModal={this.closeModal}/>	 
    
    } else if(str === 'Time_to_CABG') {
        return  <Time_to_CABG closeModal={this.closeModal}/>	 
    
    } else if(str === 'IPE_zoom') {
  		  return  <IPE_zoom closeModal={this.closeModal}/>	 
    
    } else if(str === 'chart-2.3') {
  		  return  <Chart2_3 closeModal={this.closeModal}/>
    
    }  else if(str === 'savingsCardModal') {
        return  <SavingsCardModal closeModal={this.closeModal}/>

      }  else if(str === 'BlinkrxCardModal') {
        return  <BlinkrxCardModal closeModal={this.closeModal}/>
      

    }  else if(str === 'chart-3.3') {
        return  <Chart3_3 closeModal={this.closeModal}/>

    }  else if(str === 'leavingsite-modal') {
        return  <LeavingSiteModal closeModal={this.closeModal} href={this.props.href}/>            	 
    } else {
      return null
    }
  }

  render() {
    let modal
    return (modal = this.getModal(this.props.modal))
  }
}
