import React from "react"
import "./modal.css"
import ReductionsChart from "../../images/graphs/desktop/other_secondary_endpoint_chart.png"

const Chart1_2_2 = props => {
  // Get the modal

  return (
    <div className="modal">
      <div className="modal-content-amarin chart-modal">
        <button className="close-modal" onClick={props.closeModal}>
          X
        </button>
        <img className="modal-chart" src={ReductionsChart} alt="" />
      </div>
    </div>
  )
}

export default Chart1_2_2
