import React from "react"
import "./modal.css"
import Image from '../../images/graphs/desktop/desktop_35.png'




const FirstCoronaryRevasc = (props) => {
	// Get the modal

    return (
		<div className="modal">
            <div className="modal-content-amarin chart-modal modal-title">
             <button className="close-modal" onClick={props.closeModal}>X</button>
             <h4 className="magenta">Reductions in total primary composite endpoint events</h4>
                <img className="modal-chart" src={Image} alt=""/>
            </div>
        </div>

    )
}

export default FirstCoronaryRevasc
