import React, { Component } from "react"
import "./modal.css"
import vascepa_logo from "../../images/vascepa-logo.svg"


const ModalMarkup = (props) => {

    return (
   		<div className="modal">
			<div className="content-position-div">
				<div className="home-modal-content">
					<img src={vascepa_logo} alt=""/>
					<p className="welcome-header">Welcome</p>
					<p className="continue-para">By clicking "Continue to the HCP site," you are certifying that you are a licensed US healthcare professional (HCP) and a US resident.</p>
					<button className="cta-btn" onClick={props.closeModal}>Continue to the HCP site </button>
					<p className="modal-footnote">If you are a patient who wants to learn more about VASCEPA, <span style={{whiteSpace:"nowrap"}}>click <a href="https://vascepa.com/">vascepa.com</a></span></p>
				</div>
		 	</div>
		</div>
  	)
}


export default class HomeModal extends Component {
    constructor(props) {
	    super(props)
	    this.state = {
	    	sessionSet: false
	    }
	    this.closeModal = this.closeModal.bind(this);
  	}

   	componentDidMount() {
   		// console.log('componentDidMount HomeModal');
   		// Get saved data from sessionStorage
		let data = sessionStorage.getItem('vascepa-certify');
		if (data !== null) {
			// console.log('session set');
			this.setState({ sessionSet: true });
		} else {
			// console.log(data)
			this.setState({ sessionSet: false });
		}
		
		window.addEventListener('scroll', this.animationInViewport);

   	}

    closeModal(e) {
   		// console.log(e.type,'close-modal HomeModal');
   		// this.setState({ sessionSet: true });
   		sessionStorage.setItem('vascepa-certify', true);
   		this.update();
	   }
	   
	   animationInViewport = () => {
		var animations = document.getElementsByClassName("animation-container")
		for (let i=0; i< animations.length; i++){
		var animation = animations[i]

		var top = animation.offsetTop;
		var left = animation.offsetLeft;
		var width = animation.offsetWidth;
		var height = animation.offsetHeight;

		var animationOffset = animation
	  
		while(animationOffset.offsetParent) {
		  animationOffset = animationOffset.offsetParent;
		  top += animationOffset.offsetTop;
		  left += animationOffset.offsetLeft;
		}
	  
		var inViewport =
		  (top >= window.pageYOffset &&
		  left >= window.pageXOffset &&
		  (top + height) <= (window.pageYOffset + window.innerHeight) &&
		  (left + width) <= (window.pageXOffset + window.innerWidth));

		// console.log(top)
		// console.log(left)
		// console.log(width)
		// console.log(height)
		// console.log(animationOffset)
		//
		// console.log(animations[0].classList[0] + inViewport)
		if( inViewport && !animation.classList.contains("start")) { 
		  animation.classList.add("start")}

		}
	
	
	  }

   	update() {
   		this.setState({
   			sessionSet: true
   		});
   	}

   render() {
   		const component = this.state.sessionSet ?  null :  <ModalMarkup closeModal={this.closeModal} />

    	return (
	   		<>
	   		  {component}
	   		</>
    	)
 	}
}
