import React from "react"
import { Link } from "gatsby"
import "./header.css"
import vascepa_logo from "../../../images/vascepa-logo.svg"
import arrow_down from "../../../images/down-arrow.svg"

// import AboutAMmario from "../../modal/AmeronModal"

const TopNav = props => {
  return (
    <div className="desktop-top-nav-container">
      <div className="top-nav-wrapper">
        <div className="desktop-top-nav">
          <a className="top-nav-link" href="#isi-main-container">
            <p>Important Safety Information</p>
          </a>
          <a
            className="top-nav-link"
            href="https://amarincorp.com/docs/Vascepa-PI.pdf"
            target="_blank"
          >
            <p className="no-border-right">Full Prescribing Information</p>
          </a>
        </div>
        <div className="desktop-top-nav">
          <div style={{ marginRight: "1em" }}>
            <p>For US Healthcare Professionals Only</p>
          </div>
          <a className="top-nav-link" onClick={props.goModal}>
            <p>About Amarin</p>
          </a>
          <a
            target="_blank"
            className="top-nav-link"
            href="https://www.vascepa.com/"
          >
            <p className="no-border-right">Patient Website</p>
          </a>
        </div>
      </div>
    </div>
  )
}

const DesktopHeader = props => {
  function goModal(targ) {
    // console.log("go modal")
    props.goModal("Amarin")
  }

  return (
    <>
      <div
        className={
          props.hideNav ? "scrollUp desktop-mainNav" : "desktop-mainNav"
        }
        id="mainNav"
      >
        <TopNav goModal={goModal} />
        <nav className="desktop-bottom-navbar wrapper">
        <Link to="/" className="desktop-home-btn"></Link>
        
          <div className="dropdown">
            <Link
              to="/cv-outcomes/"
              activeClassName="desk-active-nav active-pink"
              className="desktop-bottom-navbar-container"
            >
              CV
              <br />
              Outcomes
              <img className="down-arrow" src={arrow_down} alt="" />
            </Link>

            <div className="dropdown-content">
              <Link
                to="/the-vascepa-patient/"
                activeClassName="drop-desk-active-nav"
              >
                The VASCEPA Patient
              </Link>
              <Link to="/outcomes-data/" activeClassName="drop-desk-active-nav">
                CV Outcomes Data
              </Link>
              <Link
                to="/total-events-analysis/"
                activeClassName="drop-desk-active-nav"
              >
                Total Events Analysis
              </Link>
              <Link
                to="/coronary-revascularization-data/"
                activeClassName="drop-desk-active-nav"
              >
                Coronary Revascularization Analysis
              </Link>
              <Link to="/prior-mi-subgroup-data/" activeClassName="drop-desk-active-nav">
              Prior MI Subgroup Data
              </Link>
              <Link to="/safety/" activeClassName="drop-desk-active-nav">
                Safety & Side Effects
              </Link>
            </div>
          </div>

          <div className="dropdown">
            <Link
              to="/why-vascepa/"
              activeClassName="desk-active-nav active-purple"
              className="desktop-bottom-navbar-container"
            >
              Why
              <br />
              VASCEPA?
              <img className="down-arrow" src={arrow_down} alt="" />
            </Link>

            <div className="dropdown-content">
              <Link
                to="/vascepa-difference/"
                activeClassName="drop-desk-active-nav"
              >
                The VASCEPA Difference
              </Link>
              <Link to="/guidelines/" activeClassName="drop-desk-active-nav">
                Guidelines
              </Link>
              
            </div>
          </div>
          <div className="dropdown logo">
            <Link to="/">
              <img className="vascepa_logo" src={vascepa_logo} alt="VASCEPA logo" />
            </Link>
          </div>

          

          <div className="dropdown">
            <Link
              to="/getting-patients-started/"
              activeClassName="desk-active-nav active-blue"
              className="desktop-bottom-navbar-container"
            >
              Getting Patients
              <br />
              Started
              <img className="down-arrow" src={arrow_down} alt="" />
            </Link>

            <div className="dropdown-content">
              <Link to="/dosing/" activeClassName="drop-desk-active-nav">
                Dosing
              </Link>
              <Link
                to="/get-vascepa-now/"
                activeClassName="drop-desk-active-nav"
              >
                Get VASCEPA Now
              </Link>
              <Link
                to="/access-and-coverage/"
                activeClassName="drop-desk-active-nav"
              >
                Access & Coverage
              </Link>
              
            </div>
          </div>
          <div className="dropdown">
            <Link
              to="/helpful-resources/"
              activeClassName="desk-active-nav active-blue"
              className="desktop-bottom-navbar-container"
            >
              Helpful
              <br />
              Resources
              <img className="down-arrow" src={arrow_down} alt="" />
            </Link>

            <div className="dropdown-content">
              <Link to="/resources/hcps/" activeClassName="drop-desk-active-nav">
                HCP Resources
              </Link>
              <Link
                to="/resources/pharmacists/"
                activeClassName="drop-desk-active-nav"
              >
                Pharmacist Resources
              </Link>
              <Link
                to="/patient-resources/"
                activeClassName="drop-desk-active-nav"
              >
                Patient Resources
              </Link>
             
            </div>
          </div>
          <div className="savings-card-container">
            <Link to="/get-vascepa-now" className="desktop-nav-btn">
              See Savings
            </Link>
          </div>
        </nav>
      </div>
    </>
  )
}

export default DesktopHeader
