import React from "react"
import { Link } from "gatsby"
import "./mobileheader.css"
import vascepa_logo from "../../../images/vascepa-logo.svg"
import burger_icon from "../../../images/bars-solid.svg"
import arrow_down from "../../../images/down-arrow.svg"

const TopNav = props => {
  return (
    <div className="mobile-top-nav-container">
      <div className="mobile-top-nav-grid-item mob-nav-border-bot">
        <p className="mobile-top-nav-text">
          <a href="#isi-main-container">Important Safety Information</a>
        </p>
      </div>
      <div className="mobile-top-nav-grid-item mob-nav-border-bot mob-nav-border-left">
        <p className="mobile-top-nav-text">
          <a href="https://amarincorp.com/docs/Vascepa-PI.pdf" target="_blank">
            Full Prescribing Information
          </a>
        </p>
      </div>
      <div className="mobile-top-nav-grid-item">
        <p className="mobile-top-nav-text">
          For US Healthcare Professionals Only
        </p>
      </div>
      <div className="mobile-top-nav-grid-item mob-nav-border-left">
        <p className="mobile-top-nav-text">
          <a href="https://www.vascepa.com/">Patient Website</a>
        </p>
      </div>
    </div>
  )
}

const BurgerMenu = props => {
  function handleClick(e) {
    e.preventDefault()
    let content = document.querySelector(".mobile-dropdownlinks-container")
    if (content.style.maxHeight) {
      content.style.maxHeight = null
    } else {
      content.style.maxHeight = "100vh"
    }
  }

  function onChangeHandler(e) {
    // console.log('checked', e.target.value);
    let content = document.querySelector(".mobile-dropdownlinks-container")
    if (content.style.maxHeight) {
      content.style.maxHeight = null
    } else {
      content.style.maxHeight = "100vh"
    }
  }

  return (
    <div className="burger-nav-container">
      {/*<img className="burger-nav-icon" onClick={handleClick} src={burger_icon} alt=""/>*/}
      <div className="menu-header">
        <p className="menu-label">menu</p>
        <input
          className="menu-btn"
          type="checkbox"
          id="menu-btn"
          onChange={onChangeHandler}
        />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
      </div>

      <Link to="/">
        <img className="burger-nav-logo" src={vascepa_logo} alt="" />
      </Link>
      <Link className="burger-nav-button" to="/get-vascepa-now/#savings">
        See
        <br />
        Savings
      </Link>
    </div>
  )
}

/*const BottomNav = (props) => {
    return (
        <div className="mobile-bottom-nav-container">
            <div className="mobile-bottom-link-container">
                <p className="mobile-bottom-links"><a href="/">Important Safety Information</a></p>
            </div>
            <div className="mobile-bottom-link-container">
                <p className="mobile-bottom-links"><a href="/">Full Prescribing Information</a></p>
            </div>
        </div>
    )
}*/

const MobileHeader = props => {
  // console.log(window.scrollY)

  // console.log(elem.offsetHeight)
  // // var endpoint = document.querySelector(".endpoint-dropdown-container");
  //let h = elem ? elem.offsetHeight : return;

  // let elem = document.querySelector(".mainNav");

  // if (props.scrollPos > h ) {
  //     elem.classList.add("offscreen");
  //     // endpoint.classList.add("stick-it");

  // } else {
  //     elem.classList.remove("offscreen");
  //     // endpoint.classList.remove("stick-it");
  // }

  function showDropdown(e) {
    e.preventDefault()
    let targ = e.target.dataset.toggle
    e.target.classList.toggle("drop-active")
    let element = document.querySelector(
      `.mobile-dropdownlinks-content[data-targ=${targ}]`
    )
    if (element.style.maxHeight) {
      element.style.maxHeight = null
    } else {
      element.style.maxHeight = "100vh"
    }
  }

  return (
    <header
      className={props.hideNav ? "mainNav offscreen-nav " : "mainNav"}
      id="mainNav"
    >
      <TopNav />
      <BurgerMenu />
      <div className="mobile-dropdownlinks-container">
        <div className="mobile-dropdownlinks-nav">
          <Link className="mobile-dropdownlink-btn" to="/cv-outcomes/">
            CV Outcomes
          </Link>
          <div
            className="drop-toggle-btn"
            onClick={showDropdown}
            data-toggle="dropdown-one"
          ></div>
        </div>
        <div className="mobile-dropdownlinks-content" data-targ="dropdown-one">
          <Link to="/the-vascepa-patient/" activeClassName="mob-active-nav">
            The VASCEPA Patient
          </Link>
          <Link to="/outcomes-data/" activeClassName="mob-active-nav">
            CV Outcomes Data
          </Link>
          <Link to="/total-events-analysis/" activeClassName="mob-active-nav">
            Total Events Analysis
          </Link>
          <Link
            to="/coronary-revascularization-data/"
            activeClassName="mob-active-nav"
          >
            Coronary Revascularization Analysis
          </Link>
          <Link to="/prior-mi-subgroup-data/" activeClassName="mob-active-nav">
          Prior MI Subgroup Data
          </Link>
          <Link to="/safety/" activeClassName="mob-active-nav">
            Safety & Side Effects
          </Link>
        </div>

        <div className="mobile-dropdownlinks-nav">
          <Link className="mobile-dropdownlink-btn" to="/why-vascepa/">
            Why VASCEPA?
          </Link>
          <div
            className="drop-toggle-btn"
            onClick={showDropdown}
            data-toggle="dropdown-two"
          ></div>
        </div>
        <div className="mobile-dropdownlinks-content" data-targ="dropdown-two">
          <Link to="/vascepa-difference/" activeClassName="mob-active-nav">
            The VASCEPA Difference
          </Link>
          <Link to="/guidelines/" activeClassName="mob-active-nav">
            Guidelines
          </Link>
          
        </div>

        <div className="mobile-dropdownlinks-nav">
          <Link
            className="mobile-dropdownlink-btn"
            to="/getting-patients-started/"
          >
            Getting Patients Started
          </Link>
          <div
            className="drop-toggle-btn"
            onClick={showDropdown}
            data-toggle="dropdown-three"
          ></div>
        </div>
        <div
          className="mobile-dropdownlinks-content"
          data-targ="dropdown-three"
        >
          <Link to="/dosing/" activeClassName="mob-active-nav">
            Dosing
          </Link>
          <Link to="/get-vascepa-now/" activeClassName="mob-active-nav">
            Get VASCEPA Now
          </Link>
          <Link to="/access-and-coverage/" activeClassName="mob-active-nav">
            Access & Coverage
          </Link>
          
        </div>
        <div className="mobile-dropdownlinks-nav">
          <Link
            className="mobile-dropdownlink-btn"
            to="/helpful-resources/"
          >
            Helpful Resources
          </Link>
          <div
            className="drop-toggle-btn"
            onClick={showDropdown}
            data-toggle="dropdown-four"
          ></div>
        </div>
        <div
          className="mobile-dropdownlinks-content"
          data-targ="dropdown-four"
        >
          <Link to="/resources/hcps/" activeClassName="mob-active-nav">
          HCP Resources
          </Link>
          <Link to="/resources/pharmacists/" activeClassName="mob-active-nav">
          Pharmacist Resources
          </Link>
          <Link to="/patient-resources/" activeClassName="mob-active-nav">
            Patient Resources
          </Link>
        </div>
      </div>
      <div className="gradient-divider gradtop"></div>
    </header>
  )
}

export default MobileHeader
