import React from "react"
import "./modal.css"
import fpochart from "../../images/graphs/desktop/savings_chart_updated.png"


const Chart1_2_3 = (props) => {
    return (
        <div className="modal">
         	<div className="modal-content-amarin chart-modal-wide">
                <button className="close-modal" onClick={props.closeModal}>X</button>
                <img className="modal-chart" src={fpochart} alt=""/>
          	</div>
        </div>
    )
}

export default Chart1_2_3
