import React from "react"
import "./modal.css"

const AmarinModal = (props) => { 
	// Get the modal

    return (
      <div id="AmarinModal" className="modal" >
       	<div className="modal-content-amarin">
          <button onClick={props.closeModal} className="close-modal" >X</button>
          <h1 className="headline-modal-amarin">ABOUT AMARIN</h1>
          <h2 className="subhead-modal-amarin">AMARIN - GUIDED BY SCIENCE; DRIVEN TO HELP IMPROVE PATIENT CARE</h2>
          <p>Amarin Corporation plc. is a rapidly growing, innovative pharmaceutical company focused on developing therapeutics to improve cardiovascular health. Amarin’s product development program leverages its extensive experience in lipid science and the potential therapeutic benefits of polyunsaturated fatty acids. This focus includes a commitment to research and education in cardiovascular health.</p>	
          <p>High levels of quality, integrity and collaboration are embedded in Amarin’s culture. Amarin, through its dedicated team of professionals, constantly seeks to improve patient care through its actions and products while also striving to continuously improve along the way. By working together and supporting these efforts, inside and outside of the company, Amarin can empower, share and learn as it strides toward the unified goal of excellence—and beyond.</p>
        </div>
      </div>
    ) 
}

export default AmarinModal