import React from "react"
import "./modal.css"
import Image from '../../images/zoom_charts/1.5/desktop_34.jpg'




const FirstCoronaryRevasc = (props) => {
	// Get the modal

    return (
		<div className="modal">
            <div className="modal-content-amarin chart-modal modal-title">
             <button className="close-modal" onClick={props.closeModal}>X</button>
             <h4 className="magenta">Time to first coronary revascularization</h4>
                <img className="modal-chart" src={Image} alt=""/>
            </div>
        </div>

    )
}

export default FirstCoronaryRevasc
