import React, { Component } from "react"
import  "./isi.css"
import Cookie from "../cookie/Cookie"
import _ from 'underscore'
import $ from 'jquery/dist/jquery.slim'
import downArrow from "../../images/down-arrow.svg"

export default class ISI extends Component {
   constructor(props) {
     super(props)
     this.state = {
       fixed: true,
       isiTarget: '#footer',
       proximity: null
     }
     this.handleScroll = _.throttle(this.handleScroll.bind(this), 100)
   }

   componentDidMount() {
      if (typeof window !== 'undefined') {
          window.addEventListener('scroll', this.handleScroll, false);
          window.addEventListener('resize', this.handleScroll,false);
          this.footer();
          document.getElementById('isi').style.maxWidth = window.innerWidth + "px"
      }
       this.checkCookie();
       this.footer();
       document.getElementsByClassName("stick")[0] ? console.log("stick") : document.getElementsByClassName("down-arrow")[0].setAttribute("style","display:none;")

   }

   componentWillUnmount() {
       window.removeEventListener('scroll', this.handleScroll, false);
       window.removeEventListener('resize', this.handleScroll,false);

   }

  closeCookie = () => {
     this.setCookie("vascepaPrivacy", true, 365);
     this.checkCookie();
   }

   checkCookie = () => {
     const cookie = document.querySelector('.cookies');
       var cName = this.getCookie("vascepaPrivacy");
       if (cName != "") {
         // console.log("cookie set " + cName);
         cookie.style.display ="none"
         document.getElementsByClassName("isi")[0].classList.remove("cookies-active")
       } else {
           cookie.style.display ="flex"
       }
   }

   getCookie = (cname) => {
     var name = cname + "=";
     var decodedCookie = decodeURIComponent(document.cookie);
     var ca = decodedCookie.split(';');
     for(var i = 0; i <ca.length; i++){
       var c = ca[i];

       while (c.charAt(0) == ' '){
         c = c.substring(1);
       }
       if(c.indexOf(name) == 0){
         return c.substring(name.length, c.length);
       }

     }
     return ""
   }

   setCookie = (cname, cvalue, exdays) => {
     const cookie = document.querySelector('.cookies');
     var d = new Date();
          // console.log(d)
       d.setDate(d.getDate() + exdays);
          // console.log(d)
       var expires = "expires="+d.toUTCString();
       var  newCookie = cname + "=" + cvalue + ";" + expires + ";path=/"
       // console.log(newCookie)
       document.cookie = newCookie
   }








   handleScroll = () => {
      document.getElementById('isi').style.maxWidth = window.innerWidth + "px"
       // console.log("innerWidth "+ window.innerWidth)
       this.footer()
       this.referenceCheck()
       this.checkCookie()
   }

   referenceCheck = () => {
    //  console.log("refcheck fired")
    var references = document.getElementsByClassName("references")[0]
    if (references != undefined){
    var isiHeight = window.getComputedStyle(document.getElementById("isi")).height
    /*
    if(document.getElementsByClassName("stick").length > 0){
    references.setAttribute("style",`margin: 1em 0 ${isiHeight} 0 !important;`)
    }
    else{
    references.setAttribute("style","margin: 1em 0 1em 0 !important;")
    }
    */

    if(document.getElementsByClassName("stick").length > 0){
      references.setAttribute("style",`display: none`)
    }
    else{
      references.setAttribute("style",`display: block`)
    }
      }
   }

   componentDidUpdate(prevProps) {
       if (this.props.scrollPos !== prevProps.scrollPos) {
           this.checkScrolling()
       }
   }



   animateToTopOfPage(e) {
       // console.log('TODO animate to top');
       document.body.scrollTop = document.documentElement.scrollTop = 0;
   }

   scrollToIsi = () => {
    if(this.state.proximity > 0){
      var scrollLoop = setInterval(function(){
      var y = Math.ceil(window.scrollY)
      y = y + 5
       window.scrollTo(0,y)
       if (Math.ceil(window.scrollY) < y || document.getElementById("isi").getBoundingClientRect().top < 1){
        clearInterval(scrollLoop)
        // console.log(y)
        // console.log(window.scrollY)
        // console.log(Math.ceil(window.scrollY))
        // console.log("interval")
        }

      },1)
    }
    else{
      window.scrollTo(0,0)
    }
   }



   footer() {
       var proximity = this.distanceToTarget(this.state.isiTarget);
       // console.log('proximity is: '+proximity);

       const isiHeight = 140;
       proximity += isiHeight;
       this.setState({
         proximity: proximity
       })
      //  console.log('NEW proximity is: ' + proximity);

       if ( this.state.isiTarget == '#footer' && proximity <= 0 ) {
           this.setState({ isiTarget:'#isi', fixed: false });
       }

       if ( this.state.isiTarget == '#isi' && proximity > 0 ) {

           this.setState({ isiTarget:'#footer', fixed: true });
       }

   }


   distanceToTarget(target, fitsInViewport){
       var browserHeight = $(window).height();

       // if ( document.querySelector('footer')) {
       //    console.log('No target could be found.');
       //    return;
       // }
       var targetDistanceFromTop = $(target).offset().top;
       var distanceScrolled = $(window).scrollTop();

       //targetProximity is 0 when top of target is on screen
       var targetProximity = ( targetDistanceFromTop - browserHeight - distanceScrolled);
      // console.log("distance to target:" + targetProximity)
       // console.log('browserHeight is: ' + browserHeight, browserHeight);
       // console.log(target + ' is ' + targetDistanceFromTop + ' from the top of page')
       // console.log('distanceScrolled is : ' + distanceScrolled);
       // console.log('Distance to ' + target + ' is: ' + targetProximity);

       return targetProximity;
    }


    animateToTopOfPage(e) {
        // console.log('TODO animate to top');
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {
      return (
        <>

            <div style={{height:"1px",width:"100%"}} id="isi-main-container"></div>

            <div id="isi" className={this.state.fixed ? 'isi stick cookies-active ' : 'isi cookies-active' }>
              <Cookie closeCookie={this.closeCookie}/>
              <div className="box-shadow-container"><div className="isi-horizontal-box-shadow"></div></div>
                <div className="isi-container">
                    <div className="isi-main-content">
                      <div onClick={this.scrollToIsi} className="isi-down-arrow">
                        <img className="down-arrow" src={downArrow}></img>
                      </div>
                        <div className="isi-left-container">
                            <h3 className="isi-headline mobile-m-t-20">IMPORTANT SAFETY INFORMATION</h3>
                            <p className="hang-punc">
                                <span className="isi-bullet">•</span> VASCEPA is contraindicated in patients with known hypersensitivity (e.g., anaphylactic reaction) to VASCEPA or any of its components
                            </p>

                            <p className="hang-punc">
                                <span className="isi-bullet">•</span> VASCEPA was associated with an increased risk (3% vs 2%) of atrial fibrillation or atrial flutter requiring hospitalization in a double-blind, placebo-controlled trial. The incidence of atrial fibrillation was greater in patients with a previous history of atrial fibrillation or atrial flutter
                            </p>
                            <p className="hang-punc mb-0">
                                <span className="isi-bullet">•</span> It is not known whether patients with allergies to fish and/or shellfish are at an increased risk of an allergic reaction to VASCEPA. Patients with such allergies should discontinue VASCEPA if any reactions occur

                            </p>
                        </div>
                        {/* end of left container */}


                        <div className="isi-right-container">
                            <h3 className="isi-headline">INDICATIONS AND LIMITATIONS OF USE</h3>
                            <p className="hang-punc">
                                <span className="isi-bullet">•</span> VASCEPA<sup>®</sup> (icosapent ethyl) is indicated as an adjunct to maximally tolerated statin therapy to reduce the risk of myocardial infarction, stroke, coronary revascularization and unstable angina requiring hospitalization in adult patients with elevated triglyceride (TG) levels (&#8805;150 mg/dL) and established cardiovascular disease or diabetes mellitus and 2 or more additional risk factors for cardiovascular disease
                            </p>
                            <p className="hang-punc">
                                <span className="isi-bullet">•</span> VASCEPA is indicated as an adjunct to diet to reduce TG levels in adult patients with severe (&#8805;500 mg/dL) hypertriglyceridemia
                                </p>
                            <p className="body-copy">The effect of VASCEPA on the risk for pancreatitis in patients with severe hypertriglyceridemia has not been determined.
                            </p>
                        </div>
                        {/* end of right container */}

                    </div>
                    {/* end of isi-main-content */}
                    <div className="mobile-indent p-b-0">
                      <p className="hang-punc">
                          <span className="isi-bullet" >•</span> VASCEPA was associated with an increased risk (12% vs 10%) of bleeding in a double-blind, placebo-controlled trial. The incidence of bleeding was greater in patients receiving concomitant antithrombotic medications, such as aspirin, clopidogrel or warfarin
                      </p>


                      <p className="hang-punc">
                          <span className="isi-bullet">•</span> Common adverse reactions in the cardiovascular outcomes trial (incidence &#8805;3% and &#8805;1% more frequent than placebo): musculoskeletal pain (4% vs 3%), peripheral edema (7% vs 5%), constipation (5% vs 4%), gout (4% vs 3%) and atrial fibrillation (5% vs 4%)
                      </p>
                      <p className="hang-punc">
                          <span className="isi-bullet">•</span> Common adverse reactions in the hypertriglyceridemia trials (incidence &#8805;1% more frequent than placebo): arthralgia (2% vs 1%) and oropharyngeal pain (1% vs 0.3%)
                      </p>
                      <p className="hang-punc">
                          <span className="isi-bullet">•</span> Adverse Events, Product Complaints, or Special Situations may be reported by contacting AmarinConnect at <span style={{whiteSpace:"nowrap"}}>1-855-VASCEPA</span>, emailing <strong><a href="mailto:AmarinConnect@Amarincorp.com">AmarinConnect@Amarincorp.com</a></strong>,  or calling the FDA at <span style={{whiteSpace:"nowrap"}}>1-800-FDA-1088</span>
                      </p>

                      <p className="hang-punc">
                          <span className="isi-bullet">•</span> Patients receiving VASCEPA and concomitant anticoagulants and/or anti-platelet agents should be monitored for bleeding
                      </p>

                      <p className="body-copy">
                          Please see full <strong><a href="https://amarincorp.com/docs/Vascepa-PI.pdf" target="_blank">
                          Prescribing Information</a></strong> for more information on VASCEPA.
                      </p>
                      <button onClick={this.animateToTopOfPage} className="back-top-top" title="Go to top">Back To Top</button>
                      <p className="cv-footnote disclamer-isi">
                        {this.props.disclamer}                      
                      </p>
                      <div>
                        {this.props.children}
                      </div>
                    </div>



                    {/* end of ISI Main content */}

                    
                </div>
                {/* end of isi-container */}
            </div>
        </>
      )
    }
}
