import React from "react"
import "./modal.css"

import blinkrxCard from "../../images/get-vascepa-now/blinkrx-card.png"





const SavingsCardModal = (props) => {
	// Get the modal
    return (
        <div className="modal">
          <div className="modal-content-amarin chart-modal">
      			<img className="modal-chart" src={blinkrxCard} alt=""/>
      			<button className="close-modal" onClick={props.closeModal}>X</button>			   	
          </div>
        </div>   
    )
}




export default SavingsCardModal
