import React from "react"
import "./modal.css"
import Image from '../../images/graphs/desktop/desktop_32_38_34.png'




const Chart1_5_2 = (props) => {
	// Get the modal

    return (
        <div className="modal">
         	<div className="modal-content-amarin chart-modal">
			 <button className="close-modal" onClick={props.closeModal}>X</button>
				<img className="modal-chart" src={Image} alt=""/>
          	</div>
        </div>
    )
}

export default Chart1_5_2
