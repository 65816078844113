import React from "react"
import './hero.css'

const Hero = (props) => {
    return (
        <div className={`hero-container ${props.className}`}>
        	<div className={`hero-content-container heroimage ${props.bckgrdImage}`}>
        		<div className={`hero-grid ${props.className}`} style={{paddingBottom: "5px"}}>
                    <section className="hero-content">
                        {props.children}
                    </section>
        		</div>
        	</div>
        </div>
    )
}


Hero.defaultProps = {
    bckgrdImage: "heroimage"
}




export default Hero
