import React, { Component } from "react"
import ForceHash from "./ForceHash"


export default class Loader extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleLowerRender)
    document.getElementsByClassName("loader")[0].setAttribute("style","display:block")
    var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    window.scrollTo(0,scrollTop + 1)
    window.scrollTo(0, scrollTop)

    document.addEventListener("keydown", this.screenshotMode, false);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleLowerRender);
    }


  //add screenshot mode to the body for screenshot purpose only
  screenshotMode = (e) => {
    if(e.ctrlKey && e.key === "l") {
      const element = document.querySelector('body');
      element.classList.toggle('screenshot-mode');
    }
  }

    handleLowerRender = () => {
      document.getElementsByClassName("stick")[0] ?  this.removeBelowIsi() : this.insertBelowIsi()
    }

    removeBelowIsi = () => {
    var ref = document.getElementsByClassName("ref-outer")[0]
    var footer = document.getElementsByClassName("footer")[0]

    ref ? ref.setAttribute("style","opacity:0") : console.log("ref-missing")
    footer.setAttribute("style","opacity:0")
    }

    insertBelowIsi = () => {
    var ref = document.getElementsByClassName("ref-outer")[0]
    var footer = document.getElementsByClassName("footer")[0]

    ref? ref.setAttribute("style","opacity:1") : console.log("ref-missing")
    footer.setAttribute("style","opacity:1")
    }









  render() {

    const children = this.props.children;
    // const c = this.getRefs();

    return (
      <>
            <div className="loader">
              {children}
            </div>
            <ForceHash/>

      </>
    )
  }
}
