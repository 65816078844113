import React from "react"
import "./modal.css"
import primaryendpoint_chart_zoom from "../../images/zoom_charts/1.2/primaryendpoint_chart_zoom.png"

const Primaryendpoint_chart_zoom = (props) => { 
    return (
        <div className="modal">
            <div className="modal-content-amarin chart-modal modal-title">
            <button className="close-modal" onClick={props.closeModal}>X</button>
            <h4 className="magenta">Composite first occurrence of 5-point MACE (primary endpoint)</h4>
                <img className="modal-chart" src={primaryendpoint_chart_zoom} alt=""/>
            </div>
        </div>

    ) 
}

export default Primaryendpoint_chart_zoom
